// home toggle country
.full_height {
	position: relative;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	@include lg-block() {
		min-height: 100vh;
	}
}

.hl_content {
	position: relative;
	height: auto;
	display: flex;
	flex-direction: column;
	flex: 1;
}

.image_world_box {
	width: 100%;
	height: 100vh;
	overflow: hidden;
	position: absolute;
	top: 0;
	z-index: 0;
	display: inline-flex;
	align-items: center;

	@include md-block() {
		height: auto;
	}

	@include lg-block() {
		height: 100vh;
	}
}

.image_world_wrapper {
	width: 100%;
	height: 95vh;
	position: relative;
	transition: all .23s ease;

	@include md-block() {
		height: 100vh;
	}

	@include lg-block() {
		height: 95vh;
	}

	.image_world {
		position: absolute;
		right: 0;
		top: 0;
		opacity: .2;
		transition: all .3s ease;

		@include xs-block() {
			height: auto;
			top: rem(200);
		}

		@include lg-block() {
			top: 0;
			height: 100%;
		}

		&.__default {
			opacity: 1;
		}
	}

	&[data-country='asia'] > .image_world.__asia { opacity: 1; }
	&[data-country='china'] > .image_world.__china { opacity: 1; }
	&[data-country='israel'] > .image_world.__israel { opacity: 1; }
	&[data-country='africa'] > .image_world.__africa { opacity: 1; }
	&[data-country='middle_east'] > .image_world.__middle_east { opacity: 1; }
}

.home_layout {
	position: relative;
	display: flex;
	flex: 1;

	.hl_wrapper {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex: 1;

		@include lg-block() {
			gap: rem(40);
		}
	}

	.ht_text_wrapper {
		display: flex;
		flex-direction: column;

		@include lg-block() {
			flex-shrink: 0;
		}
	}

	.hl_tooltip_wrapper {
		&.__hidden {
			@include xs-block() {
				.hl_tooltip {
					transform: translateY(1000px);
				}
			}

			@include md-block() {
				.hl_tooltip {
					transform: initial;
				}
			}
		}

		@include md-block() {
			flex: 1;
			max-width: rem(300);
		}

		@include lg-block() {
			flex: initial;
			max-width: rem(520);
			order: 5;
		}
	}

	.hl_tooltip {
		transition: all .3s ease-in-out;

		.hlt_title {
			font: rem(18)/rem(24) "Montserrat-700", sans-serif;
			margin-bottom: 10px;
		}

		.hlt_description {
			font: rem(14)/rem(24) "Montserrat", sans-serif;
		}

		.btn_close {
			position: absolute;
			right: rem(23);
			top: rem(20);
			display: flex;
			background: none !important;
			color: var(--color-base);
		}

		// media
		@include xs-block() {
			position: fixed;
			left: 0;
			bottom: 0;
			padding: rem(20) rem(27);
			width: calc(100% - 54px);
			background-color: var(--color-white);
			box-shadow: 0px -4px 24px rgba(0, 0, 0, .1);
			border-top-left-radius: rem(25);
			border-top-right-radius: rem(25);
			z-index: 10;
		}

		@include md-block() {
			position: relative;
			box-shadow: none;
			background-color: #f2f5fe;
			border-radius: rem(15);
			z-index: 0;
			padding: rem(20);

			.btn_close { display: none; }
		}
	}

	.hl_title {
		color: var(--color-light-black);
		text-transform: uppercase;

		.accent {
			color: var(--color-base);
		}

		@include xs(font, (rem(18)/rem(30) "Montserrat-700", sans-serif));
		@include md(font, (rem(32)/rem(44) "Montserrat-700", sans-serif));
		@include lg(font, (rem(30)/rem(40) "Montserrat-700", sans-serif));

		@include xs-block() {
			margin-bottom: rem(15);
		}

		@include lg-block() {
			margin-bottom: rem(20);
			max-width: rem(550);
		}
	}

	.hl_subtitle {
		@include xs(margin-bottom, rem(45));
		@include md(margin-bottom, rem(20));
		@include lg-block() {
			max-width: rem(520);
		}
	}

	.hl_image_wrapper {
		position: relative;
		display: flex;
		background-size: contain;

		background-repeat: no-repeat;
		background-position: center;
		height: 100%;
		max-height: 730px;
		pointer-events: none;

		&.__mobile {
			@include xs(display, block);
			@include lg(display, none);
		}

		&.__desc {
			@include xs(display, none);
			@include lg(display, block);
		}

		@include xs-block() {
			width: 100%;
			min-height: rem(270);
			margin-bottom: rem(40);

			&[data-country='asia'] {
				right: 16%;
			}

			&[data-country='africa'],
			&[data-country='middle_east'] {
				right: 15%;
			}
		}

		@include md-block() {
			min-height: rem(300);

			&[data-country='asia'] {
				right: 0;
			}

			&[data-country='default'] {
				min-height: rem(273);
			}

			&[data-country='africa'],
			&[data-country='middle_east'] {
				right: 0;
			}
		}

		@include lg-block() {
			min-height: initial;
			margin-bottom: 0;

			&[data-country='default'] {
				min-height: rem(270);
				bottom: -20%;
			}
		}

		@media (max-height: 600px) {
			bottom: 10%;
			max-height: rem(380);

			&[data-country='israel'] {
				right: -5%;
			}

			&[data-country='africa'],
			&[data-country='middle_east'] {
				right: -5%;
			}

			&[data-country='default'] {
				max-height: rem(350);
				bottom: 0;
			}
		}

		&[data-country='default'] { background-image: url('../img/international_man/default.png'); }
		&[data-country='asia'] { background-image: url('../img/international_man/test/asia.png'); }
		&[data-country='china'] { background-image: url('../img/international_man/test/china.png'); }
		&[data-country='israel'] { background-image: url('../img/international_man/test/israel.png'); }
		&[data-country='africa'] { background-image: url('../img/international_man/test/africa.png'); }
		&[data-country='middle_east'] { background-image: url('../img/international_man/test/africa.png'); }
	}

	.hl_action_buttons {
		display: flex;

		.btn {
			white-space: nowrap;
		}

		@include xs-block() {
			flex-direction: column;
			margin-bottom: rem(30);

			.secondary {
				margin-top: rem(15);
			}
		}

		@include md-block() {
			flex-direction: row;
			margin-bottom: rem(40);

			.success {
				min-width: rem(247);
			}

			.secondary {
				margin-top: 0;
				margin-left: rem(20);
			}
		}

		@include lg-block() {
			order: 3;
			margin-top: rem(11);
			margin-bottom: 0;
		}
	}
}

.hl_country_toggle {
	@include lg-block() {
		margin-top: rem(30);
		margin-bottom: rem(30);
		order: 4;
	}

	.ct_title {
		font: rem(14)/rem(24) "Montserrat-500", sans-serif;
		color: var(--color-black);
		opacity: .8;
		margin-bottom: rem(10);
	}

	.ct_wrapper {
		display: flex;
		justify-content: space-between;
	}

	.ct_countries {
		display: flex;
		flex-wrap: wrap;
		gap: rem(10);
		z-index: 1;
		overflow-y: hidden;
		white-space: nowrap;

		@include xs-block() {
			.btn {width: auto;}
		}
	}
}

.btn_scroll_wrapper {
	width: 100%;
	margin-bottom: rem(30);
	z-index: 1;
}

.btn_box {
	display: flex;
	justify-content: flex-end;
}

.btn_scroll {
	display: none;
	margin-left: auto;

	.btn {
		border: none;
		color: var(--color-black);

		&:hover {
			color: var(--color-black);
			background-color: transparent;
			text-decoration: underline;

			& .btn_arrow {
				color: var(--color-base);
			}
		}
	}

	@include lg-block() {
		display: flex;
	}
}

// distributor characteristics
.home_characteristics_wrapper {
	background: radial-gradient(44.04% 70.89% at 19.79% 55.96%, #486FBC 0%, #1F4FAD 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}

.home_characteristics {
	position: relative;

	.hc_title_wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: var(--color-white);

		@include xs-block() {
			flex-direction: column;
			margin-bottom: rem(20);
		}

		@include md-block() {
			margin-bottom: rem(60);
		}

		@include lg-block() {
			flex-direction: row;
			margin-bottom: rem(142);
		}

		.hc_title {
			font-family: "Montserrat-700", sans-serif;

			@include xs-block() {
				font-size: rem(24);
				line-height: rem(32);
				margin-bottom: rem(20);
			}

			@include md-block() {
				font-size: rem(34);
				line-height: rem(44);
				margin-bottom: rem(40);
			}

			@include lg-block() {
				font-size: rem(36);
				line-height: rem(44);
				margin-right: rem(143);
				margin-bottom: 0;
			}
		}

		.hc_text {
			font-family: "Montserrat", sans-serif;

			@include xs-block() {
				font-size: rem(14);
				line-height: rem(24);
			}

			@include md-block() {
				font-size: rem(18);
				line-height: rem(26);
			}
		}
	}

	.hc_image {
		display: flex;
		width: 100%;

		@include xs-block() {
			margin-bottom: rem(20);

			.image {
				margin-left: auto;
				margin-right: auto;
				max-height: rem(265);
			}
		}

		@include md-block() {
			margin-bottom: 0;

			.image {
				position: absolute;
				bottom: 0;
				right: 0;
				max-height: rem(782);
			}
		}

		@include lg-block() {
			.image {
				position: absolute;
				bottom: -15%;
				margin-left: auto;
				margin-right: auto;
				left: 0;
				right: 0;
				max-height: rem(684);
				z-index: 1;
			}
		}
	}

	.hc_list {
		display: grid;
		grid-auto-flow: column;

		@include xs-block() {
			grid-template-rows: repeat(10, 1fr);
			grid-gap: rem(20);
		}

		@include md-block() {
			margin-bottom: rem(20);
		}

		@include lg-block() {
			grid-template-rows: repeat(5, 1fr);
			grid-gap: rem(40);
			justify-content: space-between;
			margin-bottom: rem(100);
		}

		.hcl_item {
			position: relative;
			display: flex;
			align-items: center;

			@include xs-block() {
				width: fit-content;
			}

			.item_close_btn {
				position: absolute;
				left: rem(88);
				top: rem(-5);
				display: none;
				flex-shrink: 0;
				align-items: center;
				justify-content: center;
				background: var(--color-base);
				border-radius: var(--border-radius-circle);
				border: 2px solid var(--color-white);
				color: var(--color-white);
				cursor: pointer;

				@include xs-block() {
					width: rem(20);
					height: rem(20);
					left: auto;
					right: rem(-10);

					svg {
						width: rem(10);
						height: rem(10);
					}
				}

				@include md-block() {
					width: rem(26);
					height: rem(26);
					left: auto;
					right: rem(-15);

					svg {
						width: rem(14);
						height: rem(14);
					}

					&:hover {
						color: var(--color-base);
						background-color: var(--color-white);
					}
				}

				@include lg-block() {
					left: rem(88);
				}
			}

			&.__accept {
				.item_close_btn {
					display: flex;
				}
			}

			&:nth-last-child(1) {
				.item_close_btn {
					@include md-block() {
						left: auto;
						right: rem(-25);
					}

					@include lg-block() {
						left: auto;
						right: rem(88);
					}
				}
			}

			&:nth-last-child(-n+5) {
				.item_close_btn {
					@include xs-block() {
						left: auto;
						right: rem(-10);
					}

					@include lg-block() {
						left: auto;
						right: rem(88);
					}
				}
			}

			@include lg-block() {
				width: 100%;

				&:nth-last-child(-n+5) {
					flex-direction: row-reverse;

					.item_number {
						margin-left: rem(40);
						margin-right: 0;
					}
				}

				.item_line {
					position: absolute;
					background-repeat: no-repeat;
					background-size: contain;
				}

				&:nth-child(1) {
					.item_line {
						width: rem(180);
						height: rem(101);
						right: -180px;
						background-image: url('../img/svg/lines/01.svg');
					}
				}

				&:nth-child(2) {
					.item_line {
						right: -240px;
						width: rem(240);
						height: rem(24);
						background-image: url('../img/svg/lines/02.svg');
					}
				}

				&:nth-child(3) {
					.item_line {
						right: -270px;
						bottom: 0;
						width: rem(270);
						height: rem(86);
						background-image: url('../img/svg/lines/03.svg');
					}
				}

				&:nth-child(4) {
					.item_line {
						right: -250px;
						bottom: -40px;
						width: rem(250);
						height: rem(176);
						background-image: url('../img/svg/lines/04.svg');
					}
				}

				&:nth-child(5) {
					.item_line {
						right: -290px;
						bottom: -45px;
						width: rem(290);
						height: rem(250);
						background-image: url('../img/svg/lines/05.svg');
					}
				}

				&:nth-child(6) {
					.item_line {
						left: -185px;
						top: 30px;
						width: rem(185);
						height: rem(104);
						background-image: url('../img/svg/lines/06.svg');
					}
				}

				&:nth-child(7) {
					.item_line {
						left: -290px;
						top: 25px;
						width: rem(290);
						height: rem(21);
						background-image: url('../img/svg/lines/07.svg');
					}
				}

				&:nth-child(8) {
					.item_line {
						left: -270px;
						bottom: 0;
						width: rem(270);
						height: rem(75);
						background-image: url('../img/svg/lines/08.svg');
					}
				}

				&:nth-child(9) {
					.item_line {
						left: -245px;
						bottom: -35px;
						width: rem(245);
						height: rem(165);
						background-image: url('../img/svg/lines/09.svg');
					}
				}

				&:nth-child(10) {
					.item_text {
						white-space: normal;
						max-width: rem(155);
					}

					.item_line {
						left: -220px;
						bottom: -45px;
						width: rem(220);
						height: rem(258);
						background-image: url('../img/svg/lines/10.svg');
					}
				}
			}

			@include xl-block() {
				&:nth-child(1) {
					.item_line {
						width: rem(308);
						height: rem(101);
						right: -308px;
						background-image: url('../img/svg/lines/01.svg');
					}
				}

				&:nth-child(2) {
					.item_line {
						right: -327px;
						width: rem(327);
						height: rem(24);
						background-image: url('../img/svg/lines/02.svg');
					}
				}

				&:nth-child(3) {
					.item_line {
						right: -369px;
						bottom: 22px;
						width: rem(369);
						height: rem(86);
						background-image: url('../img/svg/lines/03.svg');
					}
				}

				&:nth-child(4) {
					.item_line {
						right: -371px;
						bottom: 15px;
						width: rem(371);
						height: rem(176);
						background-image: url('../img/svg/lines/04.svg');
					}
				}

				&:nth-child(5) {
					.item_line {
						right: -417px;
						bottom: 25px;
						width: rem(417);
						height: rem(250);
						background-image: url('../img/svg/lines/05.svg');
					}
				}

				&:nth-child(6) {
					.item_line {
						left: -296px;
						top: 25px;
						width: rem(296);
						height: rem(104);
						background-image: url('../img/svg/lines/06.svg');
					}
				}

				&:nth-child(7) {
					.item_line {
						left: -373px;
						top: 25px;
						width: rem(373);
						height: rem(21);
						background-image: url('../img/svg/lines/07.svg');
					}
				}

				&:nth-child(8) {
					.item_line {
						left: -396px;
						bottom: 25px;
						width: rem(396);
						height: rem(75);
						background-image: url('../img/svg/lines/08.svg');
					}
				}

				&:nth-child(9) {
					.item_line {
						left: -396px;
						bottom: 22px;
						width: rem(396);
						height: rem(165);
						background-image: url('../img/svg/lines/09.svg');
					}
				}

				&:nth-child(10) {
					.item_line {
						left: -335px;
						bottom: 35px;
						width: rem(335);
						height: rem(258);
						background-image: url('../img/svg/lines/10.svg');
					}
				}
			}

			.item_number {
				font-family: "Montserrat", sans-serif;
				color: rgba(255, 255, 255, .3);
				flex-shrink: 0;

				&.__hover {
					color: var(--color-white);
				}

				@include xs-block() {
					font-size: rem(20);
					line-height: rem(24);
					margin-right: rem(20);
					flex-basis: rem(25);
				}

				@include md-block() {
					font-size: rem(36);
					line-height: rem(44);
					flex-basis: rem(48);
				}

				@include lg-block() {
					margin-right: rem(40);
				}
			}

			.item_text {
				position: relative;
				font-family: "Montserrat-500", sans-serif;
				font-size: rem(14);
				line-height: rem(17);
				color: var(--color-white);
				border-radius: var(--border-radius-big);
				box-shadow: 0 0 0 2px var(--color-white);
				white-space: nowrap;
				cursor: pointer;

				&.__hover {
					background: var(--color-white);
					color: var(--color-base);
				}

				.item_line {
					pointer-events: none;
				}

				@include xs-block() {
					font-size: rem(12);
					padding: rem(11) rem(20);
				}

				@include md-block() {
					font-size: rem(14);
					padding: rem(20);
				}
			}
		}
	}
}

// how works
.home_how_works_wrapper {
	background: radial-gradient(44.04% 70.89% at 19.79% 55.96%, #486FBC 0%, #1F4FAD 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}

.home_how_works {
	color: var(--color-white);

	.title_h1,
	.title_h2 {
		color: var(--color-white);
	}
}

.hh_steps {
	display: flex;
	justify-content: space-between;
	flex-direction: column;

	@include xs-block() {
		padding: rem(50) 0 rem(26);
		gap: rem(30);
	}

	@include md-block() {
		padding: rem(82) 0 rem(102);
		gap: rem(60);
	}

	@include lg-block() {
		padding: rem(128) 0;
		flex-direction: row;
	}

	.hh_step {
		position: relative;

		@include xs-block() {
			display: flex;
		}

		@include md-block() {
			align-items: center;
		}

		@include lg-block() {
			flex-direction: column;
			flex-basis: rem(310);
		}

		&:not(:last-child) {
			&:after {
				display: none;
				content: '';
				position: absolute;
				top: 0;
				height: 100%;
				background-repeat: no-repeat;
				background-size: contain;
				background-image: url('../img/svg/how_works/arrow.svg');
				background-position: center;

				@include lg-block() {
					display: block;
					right: 0;
					width: rem(92);
				}

				@include xl-block() {
					right: -122px;
					width: rem(92);
				}
			}
		}

		.hhs_image {
			@include xs-block() {
				margin-right: rem(20);
			}

			@include md-block() {
				margin-right: rem(30);
			}

			@include lg-block() {
				margin-right: 0;
				margin-bottom: rem(30);
			}

			.hhs_icon {
				transition: background-color .3s ease-in-out;
				border-radius: var(--border-radius-circle);

				svg {
					transition: transform .5s ease-in-out;
				}

				@include xs(display, none);
				@include md(display, flex);

				&.__mobile {
					@include xs(display, flex);
					@include md(display, none);
				}

				@include xs-block() {
					padding-left: rem(3);
					width: rem(52);
					height: rem(55);
					box-shadow: inset 0 0 0 2px var(--color-white);

					svg {
						transform: translateY(-7px);
					}
				}

				@include md-block() {
					width: rem(140);
					height: rem(140);
					box-shadow: inset 0 0 0 3px var(--color-white);

					svg {
						transform: translateY(-20px);
						max-height: rem(140);
					}
				}

				@include lg-block() {
					padding-left: rem(13);
					width: rem(167);
					height: rem(180);

					&:hover {
						background-color: var(--color-white);
						svg {
							transform: translateY(-40px);
						}
					}

					svg {
						max-height: rem(180);
					}
				}
			}
		}

		.hhs_title {
			@include xs-block() {
				font: rem(18)/rem(24) "Montserrat-700", sans-serif;
				margin-bottom: rem(10);
			}

			@include md-block() {
				font: rem(24)/rem(24) "Montserrat-700", sans-serif;
			}

			@include lg-block() {
				margin-bottom: rem(20);
			}
		}

		.hhs_subtitle {
			@include xs(font, (rem(14)/rem(24) "Montserrat", sans-serif));
			@include md(font, (rem(16)/rem(24) "Montserrat", sans-serif));
		}

		.hhs_sub_info {
			display: flex;
			align-items: center;
			justify-content: center;
			width: fit-content;
			padding: rem(9) rem(16);
			margin-top: rem(10);
			font: rem(14)/rem(14) "Montserrat", sans-serif;
			color: var(--color-base);
			background-color: var(--color-white);
			border-radius: var(--border-radius-big);
		}
	}
}

.hh_actions_wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
}

.hh_actions {
	display: flex;
	gap: rem(20);

	@include xs-block() {
		width: 100%;
		flex-direction: column;
	}

	@include md-block() {
		flex-direction: row;

		.btn {
			flex: 1;
		}
	}

	@include lg-block() {
		width: initial;

		.btn {
			flex: none;
			min-width: rem(281);
		}
	}
}

// home advantages
.home_advantages {
	.desktop_cards {
		@include xs(display, none);

		@include lg-block() {
			display: grid;
			grid-template-columns: repeat(5, 1fr);
			grid-gap: rem(20);
			margin-top: rem(80);
			margin-bottom: rem(80);
		}
	}
}

.mobile_swiper {
	@include xs(display, block);
	@include lg(display, none);
}

.swiper {
	&.__keys {
		@include xs-block() {
			padding-top: rem(30);
			width: 100%;
			height: calc(400px + 20px + 26px);
		}

		@include lg-block() {
			height: auto;
		}
	}

	&.__clients {
		@include xs-block() {
			width: 100%;
			height: calc(590px + 26px + 20px);
		}

		@include lg-block() {
			height: auto;
		}
	}
}

// custom tippy
.tippy-box[data-theme~="custom"] {
	color: var(--color-black);
	background: var(--color-white);
	box-shadow: 0 7px 24px rgba(0, 0, 0, 0.05);
	border-radius: 15px;
	padding: rem(20);
	max-width: rem(240);
}

.hc_mobile_popover_wrapper {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background-color: rgba(0, 0, 0, .4);
	z-index: 100;
	transition: visibility .2s ease-in-out;

	&.__hide {
		visibility: hidden;
		pointer-events: none;

		.hc_mobile_popover {
			transform: translateY(1000px);
		}
	}
}

.hc_mobile_popover {
	position: fixed;
	left: 0;
	bottom: 0;
	padding: rem(30) rem(20) rem(20);
	width: calc(100% - 40px);
	background-color: var(--color-white);
	box-shadow: 0px -4px 24px rgba(0, 0, 0, .1);
	border-top-left-radius: rem(25);
	border-top-right-radius: rem(25);
	z-index: 10;
	transition: all .3s ease-in-out;

	.hc_close_btn {
		position: absolute;
		right: rem(20);
		top: rem(20);
		color: var(--color-base);
	}

	.hct_text {
		padding-right: rem(20);
	}

	.btn.success {
		display: none;
	}

	@include xs(display, block);
	@include lg(display, none);

	&.__initial {
		.btn.secondary-transparent {
			display: none !important;
		}

		.btn.success {
			display: inline-flex !important;
		}
	}
}

// tooltip content
.hc_tooltip {
	display: flex;
	flex-direction: column;
	gap: rem(20);
	min-width: rem(222);

	.hct_text {
		font: rem(14)/rem(24) "Montserrat", sans-serif;
	}

	.btn {
		white-space: nowrap;
		padding: 0 rem(10);
		height: rem(50);
	}

	.btn.success {
		display: none;
	}

	&.__initial {
		.btn.secondary-transparent {
			display: none !important;
		}

		.btn.success {
			display: inline-flex !important;
		}
	}
}

// swiper customize
.swiper-pagination-bullet {
	box-shadow: inset 0 0 0 2px var(--color-base);
}
